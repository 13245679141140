import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import VideoBlock from "../../components/VideoBlock";
import About from "../../components/About";
import ConnectBlock from "../../components/ConnectBlock";
import ResilienceEPKBlock from "../../components/ResilienceEPKBlock";

import * as styles from "./styles.module.scss";
import QuotesBlock from "../../components/QuotesBlock";

const EPKPage = () => {
  return (
    <Layout title="about">
      <div className={styles.hero}>
        <StaticImage
          loading="eager"
          layout="fullWidth"
          aspectRatio={16 / 5}
          src="../../images/jesse-studio-16x5.png"
          alt="Image of musician Jesse Fischer at the Fender Rhodes in a recording studio"
        />
      </div>
      <ResilienceEPKBlock />
      <VideoBlock limit={3} />
      <ConnectBlock />
    </Layout>
  );
};

export default EPKPage;
