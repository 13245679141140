import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { MDXRenderer } from "gatsby-plugin-mdx";

import * as styles from "./styles.module.scss";

const ResilienceEPKBlock = () => {
  const { resilienceMdx, playlistsMdx, quotesMdx } = useStaticQuery(
    graphql`
      query {
        resilienceMdx: mdx(slug: { eq: "resilience" }) {
          body
          frontmatter {
            title
          }
        }
        playlistsMdx: mdx(slug: { eq: "playlists" }) {
          body
          frontmatter {
            title
          }
        }
        quotesMdx: mdx(slug: { eq: "quotes" }) {
          body
          frontmatter {
            title
          }
        }
      }
    `
  );

  return (
    <article className={styles.epk}>
      <div className={styles.sidebar}>
        <iframe
          style={{ border: 0, width: 350, height: 753 }}
          src="https://bandcamp.com/EmbeddedPlayer/album=1105466706/size=large/bgcol=333333/linkcol=0f91ff/transparent=true/"
          seamless=""
        >
          <a href="https://jessefischer.bandcamp.com/album/resilience">
            Resilience by Jesse Fischer
          </a>
        </iframe>
      </div>

      <div className={styles.body}>
        <h1 style={{ textAlign: "left" }}>about resilience</h1>
        <MDXRenderer>{resilienceMdx.body}</MDXRenderer>
        <h1 style={{ textAlign: "left" }}>playlists &amp; radio</h1>
        <MDXRenderer>{playlistsMdx.body}</MDXRenderer>
        <h1 style={{ textAlign: "left" }}>press quotes</h1>
        <MDXRenderer>{quotesMdx.body}</MDXRenderer>
      </div>
    </article>
  );
};

export default ResilienceEPKBlock;
